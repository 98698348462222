import React, { useState, useContext, useEffect } from 'react';
import { Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppGoogleAddressInput } from '@/components/ui';
import FilterContext from '@/context/filter-context/FilterContext';
import AppContext from '@/context/app-context/Context';

const SearchBar = () => {
  // Context
  const [isMyInputFocused, setIsMyInputFocused] = useState(false);
  const appContext = useContext(AppContext);
  const { setMapSettings } = appContext;
  const filterContext = useContext(FilterContext);
  const { addToListingQuery, addToChartsFilterQuery } = filterContext;
  const cachedSearchedCity = JSON.parse(localStorage.getItem('searchedCity'))?.location || '';

  // State
  const [defaultMapLocation, setDefaultMapLocation] = useState(cachedSearchedCity);

  useEffect(() => {
    const searchedCity = JSON.parse(localStorage.getItem('searchedCity'))?.city || '';
    setDefaultMapLocation(JSON.parse(localStorage.getItem('searchedCity'))?.location);
  }, [localStorage.getItem('searchedCity')]);

  useEffect(() => {
    if (cachedSearchedCity) {
      setDefaultMapLocation(cachedSearchedCity);
    } else {
      setDefaultMapLocation(localStorage.getItem('initialCity'));
    }
  }, [localStorage.getItem('initialCity')]);

  const { t } = useTranslation();

  const updateDefaultLocation = (place) => {
    const {
      address_components: addressComponents,
      formatted_address: formattedAddress,
      geometry
    } = place;
    if (place?.name !== '') {
      setMapSettings({ center: [geometry?.location?.lat(), geometry?.location?.lng()] });
    } else {
      setMapSettings({ center: [59.9071759, 10.77532260000001] });
    }
    const address = addressComponents;
    let city;
    let states;

    address?.forEach((component) => {
      const { types } = component;
      if (types.indexOf('locality') > -1) {
        city = component.long_name;
      }

      if (types.indexOf('administrative_area_level_2') > -1) {
        states = component.short_name;
      }
    });
    addToListingQuery({ city: city || states, search: true });
    addToChartsFilterQuery({ city: city || states });
    const lat = geometry?.location?.lat();
    const lng = geometry?.location?.lng();
    localStorage.setItem(
      'searchedCity',
      JSON.stringify({
        city: city || states,
        location: formattedAddress,
        lat,
        lng
      })
    );
  };

  const updateStateValue = (place) => {
    const { formatted_address: formattedAddress, geometry } = place;
    setMapSettings({ center: [geometry?.location?.lat(), geometry?.location?.lng()] });
    setDefaultMapLocation(formattedAddress);
  };

  const navigate = useNavigate();
  const navigateToMap = () => {
    navigate('/find-home');
  };

  return (
    <div className="googleSearchBar">
      <div className="input-group ps-2 ps-sm-0">
        <span
          tabIndex="0"
          role="button"
          onClick={navigateToMap}
          className={`input-side-text text-muted d-flex justify-content-center align-items-center ${
            isMyInputFocused ? 'focused' : ''
          }`}
        >
          <Search />
        </span>

        <AppGoogleAddressInput
          callback={updateDefaultLocation}
          id="js-find-a-home-defaultMapLocation"
          dataId="defaultMapLocation"
          placeholderText={t('find_a_home.index.where_are_you_moving')}
          setValue={updateStateValue}
          value={defaultMapLocation}
          className="form-control"
          onFocus={() => setIsMyInputFocused(true)}
          onBlur={() => setIsMyInputFocused(false)}
          addToListingQuery={addToListingQuery}
        />
      </div>
    </div>
  );
};

export default SearchBar;
