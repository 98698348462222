import React from 'react';

const CurveSvg2 = () => (
  <svg
    width="100%"
    height="101%"
    viewBox="0 0 721 327"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M721 0C668.659 27.2135 517.671 80.7435 332.45 63.2621C147.228 45.7808 33.6407 94.0164 0 120.319V327H721V0Z"
      fill="#1C2E45"
    />
  </svg>
);

export default CurveSvg2;
