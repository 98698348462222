import React from 'react';
import ReactDOM from 'react-dom';
import '@/i18n';
import Main from '@/Main';
import './assets/css/main.css';
import reportWebVitals from '@/reportWebVitals';
import { LayoutContext, SidebarContext, ThemeContext } from '@/components/layout/context';
import { PusherProvider } from '@/context/pusher-context';

ReactDOM.render(
  <React.StrictMode>
    <LayoutContext>
      <SidebarContext>
        <ThemeContext>
          <PusherProvider>
            <div className="container-scroller">
              <Main />
            </div>
          </PusherProvider>
        </ThemeContext>
      </SidebarContext>
    </LayoutContext>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
