/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { modalClose } from '@/core/utils';
import { AppFilterButton } from '..';
import { AppButton } from '../button';

const AppCheckBox = ({
  data,
  id,
  callBack,
  context,
  options: {
    heading,
    customClass = '',
    type,
    grid,
    btnProps,
    languageKey = '',
    checkedAll,
    customBtnGap = 'me-3 me-md-4 mb-3 mb-md-4',
    passState = false,
    state,
    setState
  } = {}
}) => {
  const { t } = useTranslation();
  const getChecked = () => {
    if (passState) {
      return state;
    }
    return data;
  };
  const [initialValue, setInitialValue] = useState(getChecked());

  const [filterData, setFilterData] = useState([]);

  const handleChange = (label) => {
    const updateState = initialValue.map((item) => {
      const items = item;
      if (label === items.label) {
        items.checked = !items.checked;
      }
      return items;
    });

    const checkedItem = updateState.filter((item) => item.checked === true);
    setFilterData(checkedItem);

    if (type !== 'filter') {
      if (callBack) {
        callBack(checkedItem, context);
      }
    }

    if (passState) {
      setState(updateState);
    }
  };

  const handleAllChecked = (e) => {
    const updateState = initialValue.map((item) => {
      const itemVal = item;
      itemVal.checked = e.target.checked;
      return itemVal;
    });

    const checkedItem = updateState.filter((item) => item.checked === true);
    setFilterData(updateState);

    if (type !== 'filter') {
      if (callBack) {
        callBack(checkedItem);
      }
    }

    if (passState) {
      setState(updateState);
    }
  };

  // Clear Check items
  const handleClear = (reference) => {
    setInitialValue(
      initialValue.map((item) => {
        const items = item;
        if (items.checked) {
          items.checked = false;
          return items;
        }
        return items;
      })
    );
    setFilterData([]);
    if (passState) {
      setState(initialValue);
    }

    if (callBack) {
      callBack(context, filterData);
    }

    modalClose(reference);
  };

  const handleApply = (item) => {
    if (callBack) {
      callBack(filterData);
    }
    modalClose(item);
  };

  return (
    <div className="customized-checkbox">
      {(() => {
        if (type === 'filter') {
          return (
            <FilterCheckBox
              t={t}
              customClass={customClass}
              grid={grid || 2}
              data={initialValue}
              heading={heading}
              id={id}
              onChange={handleChange}
              handleClear={handleClear}
              handleApply={handleApply}
              btnProps={btnProps}
              filterData={filterData}
              languageKey={languageKey}
            />
          );
        }

        if (type === 'checkbox-btn') {
          return (
            <CheckboxButton
              t={t}
              customClass={customClass}
              data={initialValue}
              id={id}
              onChange={handleChange}
              customBtnGap={customBtnGap}
              languageKey={languageKey}
              handleAllChecked={handleAllChecked}
              checkedAll={checkedAll}
            />
          );
        }

        return (
          <CheckBox
            t={t}
            customClass={customClass}
            grid={grid || 4}
            data={initialValue}
            id={id}
            onChange={handleChange}
            languageKey={languageKey}
          />
        );
      })()}
    </div>
  );
};

const CheckBox = ({ customClass, grid, data, id, onChange, t, languageKey }) => (
  <div className={` ${customClass} row`}>
    {data &&
      data.map((item) => (
        <div key={item.id} className={`col-sm-6 col-lg-4 ${item.disabled && 'disabled'} `}>
          <label
            htmlFor={`${id}-checkbox-${item.id}`}
            className="customized-checkbox-container poppins-400 text-gray font-14"
          >
            {languageKey === '' ? (
              <span className="">{item.label}</span>
            ) : (
              <span className="">{t(`${languageKey}.${item.label}`)}</span>
            )}
            <input
              type="checkbox"
              id={`${id}-checkbox-${item.id}`}
              checked={item.checked}
              disabled={item.disabled}
              onChange={() => {
                onChange(item.label);
              }}
            />
            <span className={`checkmark `} />
          </label>
        </div>
      ))}
  </div>
);

const FilterCheckBox = ({
  heading,
  t,
  customClass,
  disabled,
  grid,
  data,
  id,
  onChange,
  handleClear,
  handleApply,
  btnProps,
  filterData,
  languageKey = ''
}) => {
  const handleDefaultRadioDropdownBehaviour = (e) => {
    e.stopPropagation();
  };

  const clearRef = useRef();

  return (
    <div className={`${customClass}`}>
      <AppFilterButton {...btnProps} />

      <ul
        className="dropdown-menu p-0"
        aria-labelledby="dropdownMenuButton"
        onClick={(e) => handleDefaultRadioDropdownBehaviour(e)}
        role="menu"
        ref={clearRef}
      >
        <div className="dropdown-checkbox custom-scrollbar">
          {heading ? <h2 className="text-dark-gray poppins-600 font-16 mb-3">{heading}</h2> : ''}
          <div className={`grid-${grid}`}>
            {data?.length &&
              data.map((item) => (
                <li className={` ${disabled} `} key={item.id}>
                  <label
                    htmlFor={`${id}-checkbox-${item.id}`}
                    className="customized-checkbox-container poppins-400 text-gray font-14"
                  >
                    {languageKey === '' ? (
                      <span className="">{item.label}</span>
                    ) : (
                      <span className="">{t(`${languageKey}.${item.label}`)}</span>
                    )}

                    <input
                      type="checkbox"
                      id={`${id}-checkbox-${item.id}`}
                      checked={item.checked}
                      disabled={item.disabled}
                      onChange={() => onChange(item.label)}
                    />
                    <span className="checkmark" />
                  </label>
                </li>
              ))}
          </div>
        </div>

        <div className="filter-dropdown-footer">
          <div className="dropdown-divider" />
          <div className="padding-tb-20 padding-lr-30 d-flex align-items-center justify-content-between">
            <span
              className="poppins-500 text-gray font-16"
              onClick={() => handleClear(clearRef)}
              role="button"
              tabIndex="0"
            >
              {t('common.clear')}
            </span>
            <button
              type="button"
              className={`btn btn-primary ${!filterData?.length && 'disabled'} `}
              onClick={() => handleApply(clearRef)}
            >
              {t('common.apply')}
            </button>
          </div>
        </div>
      </ul>
    </div>
  );
};

const CheckboxButton = ({
  id,
  data,
  checkedAll,
  onChange,
  customClass,
  customBtnGap,
  handleAllChecked,
  languageKey,
  t
}) => (
  <div id={id} className={customClass}>
    {data &&
      data.map((item) => (
        <AppButton
          key={item.label}
          btnClass={`${
            item.checked ? 'btn-sub-primary' : 'btn-outline-sub-primary'
          } ${customBtnGap} rounded-pill`}
          label={languageKey ? t(`${languageKey}.${item.label}`) : item.label}
          handleClick={() => onChange(item.label)}
        />
      ))}

    {checkedAll && (
      <div className="margin-t-15">
        <label
          htmlFor={`checkbox-${id}`}
          className="customized-checkbox-container poppins-400 text-gray font-14"
        >
          <span className="">{t(`${languageKey}.include_all`)}</span>
          <input type="checkbox" id={`checkbox-${id}`} onChange={(e) => handleAllChecked(e)} />
          <span className={`checkmark `} />
        </label>
      </div>
    )}
  </div>
);

export default AppCheckBox;
