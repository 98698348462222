import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import SubDomainContext from '@/context/SubDomainContext';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import AppContext from '../../context/app-context/Context';

const Layout = ({ children, subDomain }) => {
  const location = useLocation().pathname;

  return (
    <SubDomainContext.Provider
      value={{
        subDomain
      }}
    >
      <div>
        {(() => {
          if (location === '/list-your-place/preview-and-publish') {
            return '';
          }

          if (location.split('/').includes('edit-listing' && 'preview-and-publish')) {
            return '';
          }

          if (location?.split('/')?.includes('maintenance')) {
            return '';
          }
          return <Navbar />;
        })()}

        {children}
      </div>
    </SubDomainContext.Provider>
  );
};

export default Layout;
