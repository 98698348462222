import React from 'react';
import ULULogo from './ULULogo';
import ULNewLogo from './ULNewLogo';

const ULLogoSelector = () => (
  <ul-logo-selector>
    <span className="d-sm-inline d-md-none d-lg-none d-xl-none">
      <ULULogo />
    </span>
    <span className="d-none d-sm-none d-md-inline d-lg-inline d-xl-inline">
      <ULNewLogo />
    </span>
  </ul-logo-selector>
);

export default ULLogoSelector;
