import React from 'react';

const ContentWrapper = ({ children }) => (
  <div className="container-fluid page-body-wrapper d-flex">
    <div className="main-panel margin-l-0 flex-1">
      <div className="content-wrapper overflow-scroll overflow-hidden">{children}</div>
    </div>
  </div>
);

export default ContentWrapper;
