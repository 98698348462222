import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CheckCircle, XCircle } from 'react-feather';
import PhoneInput from 'react-phone-input-2';
import Calendar from 'react-date-range/dist/components/Calendar';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { size } from 'lodash';
import { useTranslation } from 'react-i18next';
import { addDays, addYears } from 'date-fns';

const AppEditableInput = (props) => {
  const {
    id,
    value = '',
    setValue,
    callBack,
    placeholderText,
    options: {
      type = 'text',
      numberType,
      dateFormat,
      minRange,
      maxRange,
      customClass = '',
      phoneNumber = {
        show: false,
        country: ''
      },
      inlineEdit = {
        show: false,
        range: [],
        editableText: ''
      },
      showBorderOnHover,
      textVariant,
      isShowCurrencyConversion,
      showWithSymbol = {
        isShow: false,
        symbol: ''
      }
    } = {}
  } = props;

  function convert(str) {
    const months = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12'
    };
    const dateNew = str.split(' ');
    if (dateFormat === 'YYYY.MM.DD') {
      return [dateNew[3], months[dateNew[1]], dateNew[2]].join('.');
    }
    if (dateFormat === 'DD.MM.YYYY') {
      return [dateNew[2], months[dateNew[1]], dateNew[3]].join('.');
    }
    return [dateNew[2], months[dateNew[1]], dateNew[3]].join('.');
  }

  // state
  const [initialValue, setInitialValue] = useState(value || 0);
  const [newValue, setNewValue] = useState(value || 0);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const convertDate = () => {
    if (newValue) {
      if (dateFormat === 'YYYY.MM.DD') {
        const newDate = `${newValue}`.split('.');
        const formatDate = [newDate[1], newDate[2], newDate[0]].join('.');
        return formatDate;
      }
      const newDate = `${newValue}`.split('.');
      const formatDate = [newDate[1], newDate[0], newDate[2]].join('.');
      return formatDate;
    }
    const getNewData = new Date();
    return getNewData;
  };

  const [date, setDate] = useState(new Date(convertDate()));
  const [sendDate, setSendDate] = useState('');
  // ref
  const ref = useRef(null);

  const handleDate = (item) => {
    const getDate = convert(`${item}`);
    setNewValue(getDate);
    setSendDate(item);
  };

  // edit input element
  const handleEdit = useCallback(() => {
    setEdit(true);
  }, []);

  // update input element
  const handleUpdate = async () => {
    if (size(newValue.toString())) {
      if (callBack) {
        let updatedData = {};
        if (type !== 'number') {
          updatedData = {
            data: {
              name: id,
              valueString: id === 'birthday' ? sendDate : newValue
            }
          };
        } else {
          updatedData = {
            data: {
              name: id,
              valueInt: newValue
            }
          };
        }
        const response = await callBack(updatedData);

        if (!response?.error) {
          setEdit(false);
          setInitialValue(newValue);
        }
      } else {
        setEdit(false);
      }
    }
  };

  // disable button
  function btnDisabled() {
    if (
      inlineEdit.range &&
      inlineEdit.range.length &&
      inlineEdit.range[0] !== null &&
      inlineEdit.range[1] !== null
    ) {
      if (newValue >= inlineEdit.range[0] && newValue <= inlineEdit.range[1]) {
        return '';
      }

      return 'disabled';
    }

    if (inlineEdit?.range && inlineEdit.range[0] === null) {
      if (newValue <= inlineEdit.range[1]) {
        return '';
      }

      return 'disabled';
    }

    if (inlineEdit?.range && inlineEdit.range[1] === null) {
      if (newValue >= inlineEdit.range[0]) {
        return '';
      }

      return 'disabled';
    }

    if (minRange || maxRange) {
      if (newValue >= minRange || newValue <= maxRange) {
        return '';
      }
      return 'disabled';
    }

    if (newValue && newValue !== initialValue) {
      return '';
    }

    return 'disabled';
  }

  // update by enter key event
  const enterKeyEvent = async (e) => {
    if (numberType === 'int') {
      if (e.keyCode === 110 || e.keyCode === 190 || e.keyCode === 109 || e.keyCode === 107) {
        e.preventDefault();
      }
    }
    if (e.keyCode === 13) {
      if (size(newValue.toString())) {
        if (callBack) {
          let updatedData = {};
          if (type !== 'number') {
            updatedData = {
              data: {
                name: id,
                valueString: id === 'birthday' ? sendDate : newValue
              }
            };
          } else {
            updatedData = {
              data: {
                name: id,
                valueInt: newValue
              }
            };
          }

          const response = await callBack(updatedData);

          if (response) {
            if (!response.error) {
              setEdit(false);
              setInitialValue(newValue);
            }
          }
        } else {
          setEdit(false);
        }
      }
    }
  };

  // change input element
  const handleChange = (e) => {
    const changeValue = e.target.value;

    if (inlineEdit.range && inlineEdit.range.length) {
      if (changeValue >= inlineEdit.range[0] && changeValue <= inlineEdit.range[1]) {
        setError(false);
      } else {
        setError(true);
      }
    }

    if (inlineEdit.range && inlineEdit?.range.length && inlineEdit.range[0] === null) {
      if (changeValue <= inlineEdit.range[1]) {
        setError(false);
      } else {
        setError(true);
      }
    }

    if (inlineEdit?.range && inlineEdit?.range.length && inlineEdit.range[1] === null) {
      if (changeValue >= inlineEdit.range[0]) {
        setError(false);
      } else {
        setError(true);
      }
    }

    if (minRange || maxRange) {
      if (changeValue >= minRange || changeValue <= maxRange) {
        setError(false);
      } else {
        setError(true);
      }
    }
    if (numberType === 'int' && changeValue) {
      setNewValue(parseInt(changeValue.replace(/^0+/, ''), 10));
    } else {
      setNewValue(changeValue);
    }

    if (setValue) setValue(changeValue, id);
  };

  const handlePhoneNumber = (num) => {
    const modifiedNum = `+${num}`;
    setNewValue(modifiedNum);
  };

  // Handle Click Event
  const handleClickEvent = () => {
    setEdit(true);
  };

  // prevent negative input
  const preventMinus = (e) => {
    if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
      e.preventDefault();
    }
  };

  // prevent negative input on copy paste
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData('text'));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  // cancel input element
  const handleCancel = () => {
    setEdit(false);
    setNewValue(initialValue);
    if (initialValue.length) {
      const oldDate = initialValue.split('.');
      if (dateFormat === 'YYYY.MM.DD') {
        const newDate = [oldDate[1], oldDate[2], oldDate[0]].join('.');
        setDate(new Date(newDate));
      } else {
        const newDate = [oldDate[1], oldDate[0], oldDate[2]].join('.');
        setDate(new Date(newDate));
      }
    } else {
      setDate(new Date());
    }
  };

  // focus when edit is true
  useEffect(() => {
    if (!phoneNumber.show) {
      if (edit && type !== 'date') {
        ref?.current?.focus();
      }
    }
  }, [edit]);

  useEffect(() => {
    if (phoneNumber.show) {
      document.getElementsByClassName('react-tel-input')[0].classList.remove('form-control');
    }
  }, []);

  const handleDefaultDropdownBehaviour = (e) => {
    if (!type.split('-').some((item) => item === 'calendar')) {
      e.stopPropagation();
    }
  };

  useEffect(() => {
    setInitialValue(value);
    setNewValue(value);
  }, [value]);

  return (
    <>
      {(() => {
        if (type === 'date') {
          return (
            <div className="appEditableInput">
              <div className="app-dropdown  app-input-dropdown d-inline-flex align-items-center">
                <div
                  className="w-100"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={handleEdit}
                  role="button"
                  tabIndex="0"
                >
                  {!edit ? (
                    <div className="row editableInputsValue w-100">
                      <div className="col-12 ps-1">
                        <div
                          role="tab"
                          tabIndex="0"
                          className="edit-info-text d-flex justify-content-between row"
                        >
                          <span className="text-faded-black poppins-500">
                            {newValue || t('user_profile.select_a_date')}
                          </span>
                          <span className="edit-btn text-faded-black poppins-600">
                            {t('user_profile.edit')}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=" editableInputs  position-relative">
                      <div className="form-group single-item-search input-date-range">
                        <input
                          type="text"
                          className="form-control cursor-pointer "
                          value={newValue || placeholderText}
                          placeholder="Pick a date"
                          readOnly
                        />
                        <div className="select-down-icon svg-icon-20">
                          <CalendarIcon />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className={`inline-edit-input-button d-flex align-items-center margin-l-5 flex-1 ${
                    edit ? 'd-block' : 'd-none'
                  }`}
                >
                  <div
                    role="button"
                    className={`text-success me-2 svg-icon-24 ${btnDisabled()}`}
                    tabIndex="0"
                    onClick={handleUpdate}
                  >
                    <CheckCircle />
                  </div>
                  <div
                    role="button"
                    className="text-muted svg-icon-24 me-2"
                    tabIndex="0"
                    onClick={handleCancel}
                  >
                    <XCircle />
                  </div>
                </div>

                <ul
                  className="dropdown-menu  single-item-search input-date-range"
                  aria-labelledby="dropdownMenuButton"
                  onClick={handleDefaultDropdownBehaviour}
                  role="menu"
                  tabIndex="0"
                >
                  <div className="dropdown-wrapper">
                    <div className="filter-datepicker">
                      <Calendar
                        onChange={(item) => {
                          setDate(item);
                          handleDate(item);
                        }}
                        maxDate={addDays(new Date(), 0)}
                        // minDate={addYears(new Date(1980), 0)}
                        date={date}
                        showMonthArrow
                        color="#1c2e45"
                      />
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          );
        }

        return (
          <div className={`appEditableInput ${customClass} ${inlineEdit.show && 'd-inline-flex'}`}>
            {!edit ? (
              <div
                onClick={handleEdit}
                role="button"
                tabIndex="0"
                className={`${
                  inlineEdit.show ? 'pe-1 ps-1 inlineEditValue mt-1' : 'editableInputsValue'
                }  ${showBorderOnHover && 'show-border'}`}
              >
                <span
                  className={`${
                    textVariant ? `text-${textVariant}` : 'text-sub-primary'
                  }  poppins-500`}
                >
                  {(() => {
                    if (inlineEdit.show) {
                      return (
                        <>
                          {size(initialValue.toString()) ? initialValue : placeholderText}{' '}
                          <span>{inlineEdit.editableText && inlineEdit.editableText}</span>
                        </>
                      );
                    }

                    // if (isShowCurrencyConversion) {
                    //   return convertToCurrency(initialValue);
                    // }

                    if (showWithSymbol?.isShow) {
                      return (
                        <span>
                          {initialValue} {showWithSymbol?.symbol}
                        </span>
                      );
                    }

                    return size(initialValue.toString()) ? initialValue : placeholderText;
                  })()}
                </span>
              </div>
            ) : (
              <div
                className={`${
                  inlineEdit.show ? 'inline-edit-input ' : 'editableInputs  '
                } customTooltip d-flex align-items-center`}
              >
                <div className="ms-2 flex-2 ">
                  {(() => {
                    if (inlineEdit.show) {
                      return (
                        <input
                          ref={ref}
                          min="0"
                          onPaste={preventPasteNegative}
                          onKeyPress={preventMinus}
                          id={id}
                          type={type}
                          className={`form-control ${error ? 'is-invalid' : ''}`}
                          onChange={(e) => handleChange(e)}
                          onKeyDown={(e) => enterKeyEvent(e)}
                          value={newValue}
                        />
                      );
                    }

                    return (
                      <input
                        ref={ref}
                        id={id}
                        type={type}
                        step="any"
                        className={`form-control ${error ? 'is-invalid' : ''}`}
                        onInput={(e) => handleChange(e)}
                        onKeyDown={(e) => enterKeyEvent(e)}
                        onKeyPress={preventMinus}
                        value={newValue}
                      />
                    );
                  })()}
                </div>

                <div className="inline-edit-input-button d-flex align-items-center margin-l-5 flex-1">
                  <div
                    role="button"
                    className={`text-success  me-2 ${btnDisabled()}`}
                    tabIndex="0"
                    onClick={handleUpdate}
                  >
                    <CheckCircle />
                  </div>
                  <div
                    role="button"
                    className="text-muted me-2"
                    tabIndex="0"
                    onClick={handleCancel}
                  >
                    <XCircle />
                  </div>
                  <span className="text-sub-primary font-14 poppins-500">
                    {inlineEdit.editableText && inlineEdit.editableText}
                  </span>
                </div>
              </div>
            )}
          </div>
        );
      })()}
    </>
  );
};

const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-calendar"
  >
    <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
    <line x1="16" y1="2" x2="16" y2="6" />
    <line x1="8" y1="2" x2="8" y2="6" />
    <line x1="3" y1="10" x2="21" y2="10" />
  </svg>
);

export default AppEditableInput;
