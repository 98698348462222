import React, { useEffect, useReducer } from 'react';
import { size } from 'lodash';
import Cookies from 'js-cookie';
import AppContext from '@/context/app-context/Context';
import AppReducer from '@/context/app-context/Reducer';
import ActionTypes from '@/context/app-context/ActionTypes';
import { getUserInfo, getUserId, convertToCurrency } from '@/core/utils';
import { getProfileAvatar } from '@/core/utils/localStorage';
import defaultMapState from '@/views/find-home/helpers/defaultMapState';

const {
  LOADING,
  SET_LIMIT,
  SET_IS_DATA_FETCH,
  SET_CLEAR,
  DEFAULT_ZOOM,
  HIDE_MAP,
  LISTING_STATE,
  SET_DASHBOARD_TYPES,
  SET_USER,
  SET_API_CALL,
  SET_FIND_HOME_INFO,
  SET_MAP_SETTINGS,
  SET_IS_SIDE_BAR_OPEN
} = ActionTypes;

const AppState = ({ children }) => {
  const initialState = {
    isDataFetch: false,
    isLoading: false,
    limit: 150,
    isClear: false,
    hideMap: false,
    defaultZoom: 12,
    listingState: false,
    userTypes: Cookies.get(`${getUserId()}_roles`) || [],
    user: {
      avatar: getProfileAvatar(),
      name: size(getUserInfo()) ? getUserInfo()?.name : ''
    },
    apiCall: false,
    findHomeInfo: {},
    mapSettings: defaultMapState,
    isSideBarOpen: false
  };
  const [state, dispatch] = useReducer(AppReducer, initialState);

  useEffect(() => {
    console.log(state);
  }, []);

  // actions
  const setIsLoading = (value) => {
    dispatch({
      type: LOADING,
      payload: value
    });
  };

  const setLimit = (value) => {
    dispatch({
      type: SET_LIMIT,
      payload: value
    });
  };

  const setIsDataFetch = (value) => {
    dispatch({
      type: SET_IS_DATA_FETCH,
      payload: value
    });
  };

  const setIsClear = (value) => {
    dispatch({
      type: SET_CLEAR,
      payload: value
    });
  };

  const setHideMap = (value) => {
    dispatch({
      type: HIDE_MAP,
      payload: value
    });
  };

  const setListingState = (value) => {
    dispatch({
      type: LISTING_STATE,
      payload: value
    });
  };

  const setDashboardTypes = (value) => {
    dispatch({
      type: SET_DASHBOARD_TYPES,
      payload: value
    });
  };

  const setUser = (value) => {
    dispatch({
      type: SET_USER,
      payload: value
    });
  };

  const setApiCall = (value) => {
    dispatch({
      type: SET_API_CALL,
      payload: value
    });
  };

  const setFindHomeInfo = (value) => {
    dispatch({
      type: SET_FIND_HOME_INFO,
      payload: value
    });
  };

  const setMapSettings = (value) => {
    dispatch({
      type: SET_MAP_SETTINGS,
      payload: value
    });
  };

  const setDefaultZoom = (value) => {
    dispatch({
      type: DEFAULT_ZOOM,
      payload: value
    });
  };

  const setIsSideBarOpen = (value) => {
    dispatch({
      type: SET_IS_SIDE_BAR_OPEN,
      payload: value
    });
  };

  return (
    <AppContext.Provider
      value={{
        isLoading: state.isLoading,
        limit: state.limit,
        isDataFetch: state.isDataFetch,
        isClear: state.isClear,
        hideMap: state.hideMap,
        listingState: state.listingState,
        defaultZoom: state.defaultZoom,
        user: state.user,
        apiCall: state.apiCall,
        findHomeInfo: state.findHomeInfo,
        mapSettings: state.mapSettings,
        setIsLoading,
        setLimit,
        setIsDataFetch,
        setIsClear,
        setHideMap,
        setListingState,
        userTypes: state.userTypes,
        setDashboardTypes,
        setUser,
        setApiCall,
        setFindHomeInfo,
        setMapSettings,
        isSideBarOpen: state.isSideBarOpen,
        setIsSideBarOpen
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppState;
