import React from 'react';

const ULUNewLogo = () => (
  <svg width="114" height="40" viewBox="0 0 114 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4459 34.4665C11.7841 34.4665 9.63061 32.3394 9.63061 29.7104V20.1982C9.63061 14.94 5.32359 10.686 0 10.686V29.7104C0 34.9685 4.30702 39.2226 9.63061 39.2226C14.9542 39.2226 19.1943 34.9024 19.2612 29.7104C19.1943 32.2866 17.0676 34.4665 14.4459 34.4665Z"
      fill="#2B8768"
    />
    <path
      d="M19.2607 10.686V29.7104C19.2607 34.9685 23.5678 39.2225 28.8914 39.2225V1.17377C23.5811 1.17377 19.2607 5.42783 19.2607 10.686Z"
      fill="#2B8768"
    />
  </svg>
);

export default ULUNewLogo;
