import React, { useContext, useEffect, useState } from 'react';
import { Repeat, XCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { size, orderBy } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { AppModal, AppSpinner } from '@/components/ui';
import { GET_INVOICES } from '../graphql/getInvoices.gql';
import { delayCall, getFormattedDate, toastCall } from '@/core/utils';
import FilesList from '@/core/utils/FilesList';
import openFileUsingFileId from '@/views/lease/helpers/openFileUsingFileId';
import { getSelectedCurrency } from '@/core/utils/getPriceByCurrency';
import InvoiceFileModal from '@/views/lease/modals/InvoiceFileModal';
import LeaseDetailsContext from '@/context/LeaseDetailsContext';

const AllInvoicesModal = ({
  id,
  contractId,
  invoiceQuery,
  openModal,
  setOpenModal,
  setNeededFileType
}) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(true);
  const [leaseInvoiceList, setLeaseInvoiceList] = useState([]);
  const [invoiceModalData, setInvoiceModalData] = useState({});
  const { getConvertToCurrency } = useContext(LeaseDetailsContext);

  const updateLeaseInvoiceInfo = (data) => {
    if (size(data?.getAllInvoiceForPartnerPublicSite)) {
      const { getAllInvoiceForPartnerPublicSite } = data;
      if (size(getAllInvoiceForPartnerPublicSite?.data)) {
        setLeaseInvoiceList(
          orderBy(getAllInvoiceForPartnerPublicSite?.data, ['invoiceMonth'], ['desc'])
        );
      } else {
        setLeaseInvoiceList([]);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getAllInvoiceForPartnerPublicSite = async () => {
    const { data, errors } = await client.query({
      query: GET_INVOICES,
      variables: {
        queryData: { contractId, ...invoiceQuery }
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    });

    if (size(data)) {
      updateLeaseInvoiceInfo(data);
    }
    if (errors) {
      setIsLoading(false);
      delayCall(() => {
        if (errors[0].statusCode === 404) toastCall('danger', t('errors.unauthorized'));
        else toastCall('danger', t('errors.bad_request'));
      });
    }
  };

  const handleOpenInvoiceFile = async (fileId) => openFileUsingFileId(fileId);

  const modal = document.getElementById(id);
  useEffect(() => {
    if (openModal) {
      setIsLoading(true);
      getAllInvoiceForPartnerPublicSite().then((response) => {
        if (response?.errors) {
          delayCall(() => {
            if (response?.errors[0].statusCode === 404)
              toastCall('danger', t('errors.unauthorized'));
            else toastCall('danger', t('errors.bad_request'));
          });
        }
      });
    }
    modal?.addEventListener('hidden.bs.modal', () => {
      setIsLoading(true);
      setOpenModal((prev) => ({ ...prev, invoice: false }));
      setNeededFileType();
    });
  }, [openModal, invoiceQuery?.neededFileType]);

  const LoaderOrEmptyList = () => {
    if (isLoading) return <AppSpinner />;
    return t('label.nothing_found');
  };
  const statusClass = (status) => {
    let className = '';
    switch (status) {
      case 'credited':
      case 'lost':
      case 'overdue':
      case 'eviction notice due':
        className = 'danger-red-text-color';
        break;
      case 'paid':
      case 'completed':
      case 'balanced':
      case 'active':
        className = 'text-success';
        break;
      case 'fees paid':
        className = 'text-teal';
        break;
      case 'created':
      case 'new':
      case 'canceled':
        className = 'disabled';
        break;
      case 'partially paid':
        className = 'text-blue';
        break;
      case 'over paid':
        className = 'text-yellow';
        break;
      case 'fees due':
      case 'viction notice':
      case 'partially credited':
        className = 'disabled';
        break;
      default:
    }
    return className;
  };

  const closeBtn = (
    <button type="button" className="btn btn-sub-primary ms-2" data-bs-dismiss="modal">
      <XCircle /> {t('common.close')}
    </button>
  );

  const handleSetModalData = (invoiceId) => {
    const modalData = leaseInvoiceList?.find((invoice) => invoice._id === invoiceId);
    setInvoiceModalData(modalData);
  };

  return (
    <>
      <AppModal
        id={id}
        type="modal-lg"
        options={{
          title: t('leases.all_invoices'),
          modalHeader: true,
          modalFooter: true,
          customBtn: closeBtn,
          btnClose: false,
          btnCancel: false
        }}
      >
        <div className="margin-tb-30 allInvoiceModal">
          <table className="table table-striped table-borderless ">
            {(() => {
              if (!isLoading && size(leaseInvoiceList)) {
                return (
                  <>
                    <thead>
                      <tr>
                        <td className="montserrat-500 text-gray font-14 padding-l-30 padding-tb-20">
                          {t('leases.leases_status.due_date')}
                        </td>
                        <td className="montserrat-500 text-gray font-14 padding-tb-20">
                          {t('leases.reference')}
                        </td>
                        <td className="montserrat-500 text-gray font-14 padding-tb-20">
                          {t('label.amount')}
                        </td>
                        <td className="montserrat-500 text-gray font-14 padding-tb-20">
                          {t('user_profile.status')}
                        </td>
                        <td className="montserrat-500 text-gray font-14 padding-tb-20">
                          {t('common.attachment')}
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      {leaseInvoiceList?.map((invoice) => (
                        <tr key={invoice._id}>
                          <td className="padding-tb-20 montserrat-500 text-faded-black font-14 padding-l-30">
                            {invoice?.invoiceMonth
                              ? getFormattedDate(invoice?.dueDate, 'D.M.YYYY')
                              : ''}
                          </td>
                          <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                            {`#${invoice.invoiceSerialId}`}
                          </td>
                          <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                            {getConvertToCurrency(invoice?.invoiceTotal) || 0}
                          </td>
                          <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                            {invoice?.status === 'overpaid' ? (
                              <div className="d-flex align-items-center">
                                <p className="text-blue"> {invoice?.status}</p>

                                <div className="hover-tooltip-invoice margin-l-5">
                                  <div className="tooltip-msg-invoice">
                                    <div className="svg-icon-20 text-sub-primary text-center">
                                      <Repeat />
                                    </div>

                                    <p className="margin-t-15">
                                      {t(
                                        'leases.overpayment_automatically_transferred_next_invoice'
                                      )}
                                    </p>
                                  </div>
                                  <p className="status-tooltip-sign">!</p>
                                </div>
                              </div>
                            ) : (
                              <span className={`text-capitalize ${statusClass(invoice?.status)}`}>
                                {invoice?.status}
                              </span>
                            )}
                          </td>
                          <td className="padding-tb-20">
                            {(() => {
                              if (invoice?.attachments) {
                                return (
                                  <FilesList
                                    dynamicData={invoice?.attachments}
                                    number={3}
                                    modalId={`#all-lease-invoice-${contractId}`}
                                    targetId="#invoice-files-details"
                                    callBack={() => handleSetModalData(invoice._id)}
                                    handleDownload={handleOpenInvoiceFile}
                                  />
                                );
                              }
                              return '';
                            })()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                );
              }
              return (
                <span className="padding-40 margin-t-30 border-0 text-center">
                  <LoaderOrEmptyList />
                </span>
              );
            })()}
          </table>
        </div>
      </AppModal>
      <InvoiceFileModal
        id="invoice-files-details"
        data={invoiceModalData}
        handleDownload={handleOpenInvoiceFile}
      />
    </>
  );
};

export default AllInvoicesModal;
