const ActionTypes = {
  LOADING: 'LOADING',
  SET_LIMIT: 'SET_LIMIT',
  SET_IS_DATA_FETCH: 'SET_IS_DATA_FETCH',
  SET_CLEAR: 'SET_CLEAR',
  SET_USER_NAME: 'SET_USER_NAME',
  HIDE_MAP: 'HIDE_MAP',
  DEFAULT_ZOOM: 'DEFAULT_ZOOM',
  LISTING_STATE: 'LISTING_STATE',
  SET_DASHBOARD_TYPES: 'SET_DASHBOARD_TYPES',
  SET_USER: 'SET_USER',
  SET_API_CALL: 'SET_API_CALL',
  SET_FIND_HOME_INFO: 'SET_FIND_HOME_INFO',
  SET_MAP_SETTINGS: 'SET_MAP_SETTINGS',
  SET_IS_SIDE_BAR_OPEN: 'SET_IS_SIDE_BAR_OPEN'
};

export default ActionTypes;
