import React from 'react';

const CurveSvg = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 -3 721 387"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 90.001C131 156.501 219.5 167.501 359.5 133.501C478.457 104.611 565 8.00098 721 0.320313V387.001H0V90.001Z"
      fill="#F7F5F2"
    />
  </svg>
);

export default CurveSvg;
