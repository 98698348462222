import React, { useEffect } from 'react';
import { CheckCircle, ChevronLeft, ChevronRight, X, XCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';

const AppModal = ({
  children,
  id,
  type,
  options: {
    languageKey,
    headerClass = '',
    titleClass = '',
    customClass = '',
    modalDialogClass = '',
    modalHeader,
    title,
    modalFooter,
    btnApply,
    btnBack,
    handleBtnCancel,
    handleBtnApply,
    btnClose = true,
    btnNext,
    btnCancel,
    modalType = '',
    icon,
    disableApply = false,
    confirmationModalTitle,
    confirmationModalSubtitle,
    confirmationCloseBtn = true,
    handleConfirmationBtnCLick,
    customBtn,
    btnNextTarget,
    btnBackTarget,
    btnNextVariant = 'success',
    btnBackVariant = 'gray',
    btnCancelVariant = 'disabled',
    btnCloseVariant = 'disabled',
    btnApplyVariant = 'primary'
  } = {}
}) => {
  const { t } = useTranslation();
  // Close modal backdrop when click back button
  useEffect(() => {
    window.onpopstate = () => {
      document.querySelector('.modal-backdrop')?.classList.remove('modal-backdrop');
    };
  }, []);
  return (
    <div className={`modal fade ${customClass}`} id={id} tabIndex="-1">
      <div className={`modal-dialog ${type} ${modalDialogClass}`}>
        <div className="modal-content">
          {(() => {
            if (modalType === 'confirmation-modal') {
              return (
                <div className="confirmationModal padding-30 text-center">
                  <div className="confirmationModal__icon margin-tb-20">
                    <span className="text-warning">{icon}</span>
                  </div>

                  <div className="confirmationModal__text">
                    <h5 className="mb-2 title text-default">{confirmationModalTitle}</h5>
                    <p className="subtitle">{confirmationModalSubtitle}</p>
                  </div>

                  <div className="margin-t-30 margin-b-20">
                    <button
                      type="button"
                      className="btn btn-disabled modal-btn me-3"
                      data-bs-dismiss="modal"
                    >
                      {languageKey ? t(`${languageKey}.no`) : 'No'}
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning modal-btn"
                      data-bs-dismiss="modal"
                    >
                      {languageKey ? t(`${languageKey}.yes`) : 'Yes'}
                    </button>
                  </div>
                </div>
              );
            }

            if (modalType === 'success-confirmation-modal') {
              return (
                <div className="confirmationModal padding-30 text-center">
                  <div className="confirmationModal__icon margin-tb-20">
                    <span className="text-primary">{icon}</span>
                  </div>
                  <div className="confirmationModal__text">
                    <h5 className="mb-2 title text-default">{confirmationModalTitle}</h5>
                    <p className="subtitle">{confirmationModalSubtitle}</p>
                  </div>
                  <div className="margin-t-30 margin-b-20">
                    {confirmationCloseBtn && (
                      <button
                        type="button"
                        className="btn btn-disabled modal-btn me-3"
                        data-bs-dismiss="modal"
                      >
                        {languageKey ? t(`${languageKey}.close`) : 'Close'}
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-success modal-btn"
                      data-bs-dismiss="modal"
                      onClick={handleConfirmationBtnCLick}
                    >
                      {languageKey ? t(`${languageKey}.okay`) : 'Okay'}
                    </button>
                  </div>
                </div>
              );
            }
            return (
              <>
                {modalHeader ? (
                  <div className={`modal-header ${headerClass} shadow padding-30`}>
                    <div>
                      <span
                        role="button"
                        tabIndex="0"
                        className="modal-close text-secondary icon-hover"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <X />
                      </span>
                    </div>
                    <div className="d-flex flex-1 justify-content-center">
                      <h3 className={`modal-title text-center ${titleClass}`}>{title}</h3>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="modal-body custom-scrollbar">{children}</div>

                {modalFooter ? (
                  <div className="modal-footer padding-tb-20 padding-lr-30 shadow">
                    {btnBack && (
                      <button
                        type="button"
                        onClick={handleBtnCancel}
                        className={`btn btn-${btnBackVariant} me-2`}
                        data-bs-dismiss="modal"
                        data-bs-toggle="modal"
                        data-bs-target={`#${btnBackTarget}`}
                      >
                        <ChevronLeft /> {languageKey ? t(`${languageKey}.back`) : 'Back'}
                      </button>
                    )}
                    {btnCancel && (
                      <button
                        type="button"
                        onClick={() => (handleBtnCancel ? handleBtnCancel() : '')}
                        className={`btn btn-${btnApply ? btnCancelVariant : 'sub-primary'} ms-2`}
                        data-bs-dismiss="modal"
                      >
                        <XCircle /> {languageKey ? t(`${languageKey}.cancel`) : 'Cancel'}
                      </button>
                    )}

                    {btnClose && (
                      <button
                        type="button"
                        onClick={() => (handleBtnCancel ? handleBtnCancel() : '')}
                        className={`btn btn-${btnApply ? btnCloseVariant : 'sub-primary'} ms-2`}
                        data-bs-dismiss="modal"
                      >
                        <XCircle /> {languageKey ? t(`${languageKey}.clear`) : t('common.clear')}
                      </button>
                    )}

                    {customBtn && customBtn}

                    {btnApply && (
                      <button
                        type="button"
                        className={`btn btn-${btnApplyVariant} ms-2 ${
                          disableApply ? 'disabled' : ''
                        }`}
                        onClick={() => handleBtnApply()}
                        data-bs-dismiss="modal"
                      >
                        <CheckCircle /> {languageKey ? t(`${languageKey}.apply`) : 'Apply'}
                      </button>
                    )}

                    {btnNext && (
                      <button
                        type="button"
                        className={`btn btn-${btnNextVariant} ms-2`}
                        data-bs-dismiss="modal"
                        data-bs-toggle="modal"
                        data-bs-target={`#${btnNextTarget}`}
                      >
                        <ChevronRight /> {languageKey ? t(`${languageKey}.next`) : 'Next'}
                      </button>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </>
            );
          })()}
        </div>
      </div>
    </div>
  );
};

export default AppModal;
