/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { size, uniqueId } from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppContext from '@/context/app-context/Context';

const AppCheckboxes = ({
  data,
  callback,
  id,
  context,
  customClass = '',
  onChange,
  clear = false
}) => {
  const appContext = useContext(AppContext);
  const { isClear, setIsClear } = appContext;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  // state
  const [checkbox, setCheckbox] = useState(data);
  const [state, setState] = useState({});
  // Change Checkbox State
  const handleChange = (label) => {
    const copy = [...checkbox];
    const updateState = copy?.map((item) => {
      if (label === item.label) {
        item.checked = !item.checked;
        setState({ label, id: item.id, checked: item.checked });
      }
      return item;
    });
    setCheckbox(updateState);
  };

  // callback
  useEffect(() => {
    const checkedItem = checkbox?.filter((item) => item?.checked === true);
    callback && callback(checkedItem);
  }, [checkbox]);
  useEffect(() => {
    if (isClear) {
      const copy = [...checkbox];
      const clearCheckItems = copy?.map((item) => {
        const items = item;
        items.checked = false;
        return items;
      });
      setCheckbox(clearCheckItems);
      setIsClear(false);
    }
  }, [isClear]);

  // reset component data
  useEffect(() => {
    if (clear) {
      const copy = [...checkbox];
      const clearCheckItems = copy?.map((item) => {
        const items = item;
        items.checked = false;
        return items;
      });
      setCheckbox(clearCheckItems);
    }
  }, [clear]);

  useEffect(() => {
    if (pathname === '/list-your-place/additional-feature') {
      const val = JSON.parse(sessionStorage.getItem('data'));
      if (val) {
        for (const item of checkbox) {
          if (val?.facilities) {
            for (const facility of val?.facilities) {
              if (item?.label === facility?.label) {
                item.checked = !item.checked;
              }
            }
          }
        }
        setCheckbox(checkbox);
      }
    }
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [state]);

  useEffect(() => {
    if (size(localStorage.getItem('listingType') && id === 'property-filter')) {
      setCheckbox(JSON.parse(localStorage.getItem('listingTypeIds')));
    }
  }, [localStorage.getItem('listingType')]);

  return (
    <>
      <div className="row">
        {checkbox &&
          checkbox.map(
            (item, index) =>
              item && (
                <div className="col-sm-6 col-lg-4" key={uniqueId()}>
                  <div className="d-flex justify-content-start align-items-center ">
                    <CheckBox
                      item={item}
                      context={context}
                      handleChange={handleChange}
                      id={id}
                      t={t}
                      key={index + 1}
                      disabledClass={`${item.disabled ? 'disabled' : ''}`}
                      customClass={customClass}
                    />
                  </div>
                </div>
              )
          )}
      </div>
    </>
  );
};

// checkbox
const CheckBox = ({ item, handleChange, context, t, id, disabledClass, customClass }) => (
  <div className={`customized-checkbox checkbox-default-primary ${disabledClass} ${customClass}`}>
    <input
      id={`${id}-checkbox-${item.id}`}
      type="checkbox"
      checked={item.checked}
      onChange={() => handleChange(item.label)}
    />
    <label
      htmlFor={`${id}-checkbox-${item.id}`}
      className={`form-check-label ${item.checked ? 'text-faded-black' : 'text-grey'}`}
    >
      {context ? t(`${context}.${item.label}`) : item.label}
    </label>
  </div>
);

export default AppCheckboxes;
