/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  ChevronDown,
  User,
  Menu,
  Inbox,
  LogOut,
  Star,
  Filter,
  Search,
  Briefcase
} from 'react-feather';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { size } from 'lodash';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LandlordIcon, LeaseIcon } from '@/components/ui';
import SearchBar from '@/views/layout/SearchBar';
import {
  toastCall,
  getUserId,
  translateLanguage,
  getUserInfo,
  logOut,
  isAuthenticated,
  canAccessLeasePage
} from '@/core/utils';
import AppContext from '@/context/app-context/Context';
import SignInModal from '@/views/register/modalSection/SignInModal';
import { UPDATE_USER_PROFILE } from './graphql/updateUserProfile.gql';
import GET_USER_PROFILE from './graphql/getUserProfile.gql';
import PublicSiteSidebarData from '../../data/SidebarData';
import SubDomainContext from '@/context/SubDomainContext';
import NavModal from './NavModal';
import filterContext from '../../context/filter-context/FilterContext';
import ULLogoSelector from '../ui/customSvg/ULLogoSelector';

// language Data
const languageData = {
  en: 'english',
  no: 'norwegian'
};
// const krData = ['NOK(kr)', 'EURO(€)'];

const Navbar = ({ customClass = 'bg-cream-white', subDomain }) => {
  // const { handleSidebarOffCanvas } = useContext(layoutContext);
  // const { theme, handleTheme } = useContext(themeContext);
  // const { sidebar, togglingLeftMenu } = useContext(sidebarContext);
  const { subDomain: partner } = useContext(SubDomainContext);
  const appContext = useContext(AppContext);
  const { user: userInfo, userTypes } = appContext;
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [registerDropdown, setRegisterDropdown] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const [language, setLanguage] = useState(Cookies.get('language') || 'en');
  const [originalLanguage, setOriginalLanguage] = useState('en');
  const [isLogedIn, setIsLogedIn] = useState(null);
  const [disable, setDisable] = useState(false);
  // const [krDropdown, setKrDropdown] = useState(false);
  // const [currency, setCurrency] = useState(localStorage.getItem('currency') || 'NOK(kr)');

  // const krRef = useRef();
  const profileRef = useRef();
  const registerRef = useRef();
  const languageRef = useRef();
  const userId = getUserId();
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const urlLocation = location.pathname;

  const checkLoginPopup =
    urlLocation === '/register-as-tenant/create-account' ||
    urlLocation === '/register-as-landlord/create-account' ||
    urlLocation === '/login';

  useEffect(() => {
    if (size(getUserId())) {
      setIsLogedIn(Cookies.get('accessToken'));
    } else {
      setIsLogedIn(null);
    }
  }, [getUserId()]);

  const newUrl = () => {
    window.open('https://forvaltning.uniteliving.no', '_self');
  };

  // Mutation: Update Language
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);
  const changeLanguage = async (lan) => {
    i18n.changeLanguage(lan);
    setLanguage(lan);
    try {
      const result = await updateUserProfile({
        variables: {
          inputData: {
            _id: userId,
            data: {
              language: lan
            }
          }
        }
      });
      if (result) {
        setDisable(false);
      }
    } catch (e) {
      i18n.changeLanguage(originalLanguage);
      setLanguage(originalLanguage);
      setDisable(false);
      toastCall('danger', e?.message, 'top-right');
    }
  };

  const handleChange = async (item) => {
    setDisable(true);
    if (isAuthenticated()) {
      changeLanguage(item);
    } else {
      i18n.changeLanguage(item);
      Cookies.set('language', item);
      setLanguage(item);
      setDisable(false);
    }
  };

  // Change Currency State
  // const handleChangeCurrency = (item) => {
  //   localStorage.setItem('currency', item);
  //   setCurrency(item);
  // };

  // detect outside click
  const handleClickOutside = (e) => {
    // outside click
    if (!profileRef.current.contains(e.target)) {
      setProfileDropdown(false);
    }

    if (!registerRef.current.contains(e.target)) {
      setRegisterDropdown(false);
    }

    if (!languageRef.current.contains(e.target)) {
      setLanguageDropdown(false);
    }
    // if (!krRef.current.contains(e.target)) {
    //   setKrDropdown(false);
    // }
  };

  const [showSearch, setShowSearch] = useState(false);

  const toggleSearch = () => {
    setShowSearch((prevShowSearch) => !prevShowSearch);
  };

  // Query Profile Data
  const [myProfile] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'network-only',
    variables: {
      queryData: { userId }
    },
    onCompleted: (data) => {
      const userLanguage = data?.myProfile?.language || 'en';
      if (size(userLanguage)) {
        i18n.changeLanguage(userLanguage);
        setLanguage(userLanguage);
        setOriginalLanguage(userLanguage);
      }
      setDisable(false);
    }
  });

  useEffect(() => {
    setDisable(true);
    if (isAuthenticated()) {
      myProfile();
    } else if (size(language)) {
      i18n.changeLanguage(language);
      setDisable(false);
    }
  }, [isAuthenticated()]);

  useEffect(() => {
    if (profileDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileDropdown]);

  useEffect(() => {
    if (registerDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [registerDropdown]);

  useEffect(() => {
    if (languageDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [languageDropdown]);

  // useEffect(() => {
  //   if (krDropdown) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //   } else {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [krDropdown]);

  const [burgerClass, setBurgerClass] = useState('burgerMenu__burger-bar unclicked');
  const [menuClass, setMenuClass] = useState('menu hidden');
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const { listingQueryData, addToListingQuery, removeFromListingQuery } = filterContext;

  const updateMenu = () => {
    if (!isMenuClicked) {
      setBurgerClass('burgerMenu__burger-bar clicked');
      setMenuClass('menu visible');
    } else {
      setBurgerClass('burgerMenu__burger-bar unclicked');
      setMenuClass('menu hidden');
    }
    setIsMenuClicked(!isMenuClicked);
  };
  const partnerLogo = localStorage.getItem('partnerLogo');
  const checkpartnerLogo =
    partnerLogo &&
    partnerLogo !== 'null' &&
    partnerLogo !== 'https://uninite-dev.imgix.net/logo/default/organization.png';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const truncateString = (str, num) => {
    if (str?.length <= num) {
      return str;
    }
    return `${str?.slice(0, num)}...`;
  };

  const isPartner = Cookies.get('partnerId');

  // to check if the URL has protocol type
  const addProtocolToURL = (url) => {
    if (url && !url.match(/^[a-zA-Z]+:\/\//)) {
      return `https://${url}`;
    }
    return url;
  };

  const partnerSiteURL = addProtocolToURL(localStorage.getItem('partnerSiteURL'));

  // eslint-disable-next-line arrow-body-style
  const isSidebarHasItem = (linkKey) => {
    return PublicSiteSidebarData.some((item) => item.key === linkKey);
  };

  const canShowMylisintgLink = () => {
    if (partner && partner?.isSubDomainExists && isSidebarHasItem('myListing')) return false;
    return true;
  };

  const canShowLeaseLink = () => {
    if (
      subDomain ||
      subDomain?.isSubDomainExists ||
      (canAccessLeasePage(userTypes) && isSidebarHasItem('leases'))
    )
      return true;
    return false;
  };

  const isButtonDisable = () => {
    if (
      listingQueryData?.furnished ||
      listingQueryData?.space ||
      size(listingQueryData?.includedInRentIds) ||
      size(listingQueryData?.facilityIds) ||
      listingQueryData?.minimumStay ||
      listingQueryData?.availableFrom ||
      listingQueryData?.has360Url ||
      listingQueryData?.hasVideoUrl
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <nav className="publicSiteNav  fixed-navbar-top shadow default-layout-navbar navbar-expand-lg bg-white">
        <div className="container-fluid align-items-center justify-content-between row g-1 px-1">
          <div className="navbar-brand brand-logo ms-0 align-self-center padding-tb-20 col-2 col-sm-1 col-md-2 col-lg-2 p-0">
            {checkpartnerLogo ? (
              partnerSiteURL ? (
                <a href={partnerSiteURL} rel="noreferrer" target="_blank">
                  <img src={partnerLogo} alt="logo" />
                </a>
              ) : (
                <img className="contain ps-1 ps-md-2" src={partnerLogo} alt="logo" />
              )
            ) : (
              <span className="brand-logo-svg margin-l-10">
                {partnerSiteURL ? (
                  <a href={partnerSiteURL} rel="noreferrer" target="_blank">
                    <ULLogoSelector />
                  </a>
                ) : (
                  <ULLogoSelector />
                )}
              </span>
            )}
          </div>
          <div className="collapse navbar-collapse col-lg-10" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mx-4 mb-lg-0 publicNav">
              {isPartner ? (
                <>
                  <li className="nav-item">
                    <NavLink to="/find-home">
                      <div className="d-flex align-items-center nav-link ">
                        <div className="">
                          <p className="my-0">{t('homepage.find_home')}</p>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <div className="d-flex align-items-center nav-link">
                      <SearchBar />
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <NavLink to="/find-home">
                      <div
                        className="d-flex align-items-center nav-link "
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                      >
                        <div className="">
                          <p className="my-0">{t('homepage.find_home')}</p>
                        </div>
                      </div>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/list-your-place/listing-details">
                      <div
                        className="d-flex align-items-center nav-link"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                      >
                        <div className="">
                          <p className="my-0">{t('list_your_place.list_your_place')}</p>
                        </div>
                      </div>
                    </NavLink>
                  </li>

                  <div className="d-flex align-items-center nav-link">
                    <SearchBar />
                  </div>
                </>
              )}
            </ul>

            <ul className="navbar-nav ml-auto mb-2 mb-lg-0 publicNavRight d-flex justify-content-end">
              {/* <li className="nav-item dropdown" ref={krRef}>
            <div
              role="button"
              tabIndex="0"
              onClick={() => setKrDropdown(!krDropdown)}
              className="d-flex align-items-center nav-link dropdown-toggle"
              id="languageDropdown"
            >
              <span className="svg-icon-24">
                {currency === 'NOK(kr)' ? (
                  <KroneIcon />
                ) : (
                  <span
                    style={{ fontSize: '27px', fontWeight: '200' }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize('&#128') }}
                  />
                )}
              </span>
              <span className="svg-icon-18">
                <ChevronDown />
              </span>
            </div>
            <div
              className={`dropdown-menu dropdown-menu-right navbar-dropdown language-dropdown ${
                krDropdown ? 'show' : ''
              }`}
            >
              {krData.map((krParams) => (
                <Link
                  to="#"
                  key={krParams}
                  className="dropdown-item"
                  onClick={() => {
                    setKrDropdown(false);
                    handleChangeCurrency(krParams);
                  }}
                >
                  {krParams}
                </Link>
              ))}
            </div>


          </li> */}

              {isLogedIn === null ? (
                <li className="nav-item  dropdown" ref={registerRef}>
                  <div
                    className="d-flex align-items-center nav-link dropdown-toggle"
                    role="button"
                    tabIndex="0"
                    id="notificationDropdown"
                    data-toggle="dropdown"
                    onClick={() => setRegisterDropdown(!registerDropdown)}
                  >
                    <div className="">
                      <p className="my-0">{t('label.register')}</p>
                    </div>
                  </div>
                  <div
                    className={`dropdown-menu dropdown-menu-right navbar-dropdown ${
                      registerDropdown ? 'show' : ''
                    }`}
                  >
                    <div className="register-as text-muted">
                      <span>{t('label.register_as')}</span>
                    </div>
                    <Link to="/register-as-tenant">
                      <div
                        role="button"
                        tabIndex="0"
                        className="dropdown-item"
                        onClick={() => {
                          setRegisterDropdown(false);
                        }}
                      >
                        <span className="svg-icon-20 d-flex align-items-center">
                          <User />
                          <span className="ms-2">{t('label.tenent')}</span>
                        </span>
                      </div>
                    </Link>

                    {!isPartner && (
                      <Link to="/register-as-landlord">
                        <div
                          role="button"
                          tabIndex="0"
                          className="dropdown-item"
                          onClick={() => {
                            setRegisterDropdown(false);
                          }}
                        >
                          <span className="svg-icon-20 d-flex align-items-center">
                            <LandlordIcon />
                            <span className="ms-2">{t('label.landlord')}</span>
                          </span>
                        </div>
                      </Link>
                    )}
                  </div>
                </li>
              ) : (
                <li className="nav-item  dropdown" ref={registerRef} />
              )}

              <li className="nav-item dropdown" ref={languageRef}>
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => setLanguageDropdown(!languageDropdown)}
                  className="d-flex align-items-center nav-link dropdown-toggle"
                  id="languageDropdown"
                >
                  <span className="svg-icon-24">
                    {/* <Globe />  */} {language?.toUpperCase()}
                  </span>
                  <span className="svg-icon-18">
                    <ChevronDown />
                  </span>
                </div>

                <div
                  className={`dropdown-menu dropdown-menu-right navbar-dropdown language-dropdown ${
                    languageDropdown ? 'show' : ''
                  }`}
                >
                  {['en', 'no'].map((languageParams) => (
                    <span
                      role="button"
                      tabIndex="0"
                      key={languageParams}
                      className={`dropdown-item ${disable ? 'disabled' : ''}`}
                      onClick={() => {
                        setLanguageDropdown(false);
                        handleChange(languageParams);
                      }}
                    >
                      {translateLanguage(`label.${languageData[languageParams]}`)}
                    </span>
                  ))}
                </div>
              </li>

              {location.pathname === '/find-home' ? (
                <div
                  className="d-flex align-items-center nav-link me-0"
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#more-filter-model"
                >
                  <button
                    type="button"
                    className="d-flex align-items-center btn btn-lg btn-light rounded-pill"
                  >
                    <Filter />
                    <h6 className="montserrat-500 font-14 text-faded-black">Filters</h6>
                  </button>
                </div>
              ) : (
                <></>
              )}

              {isLogedIn === null ? (
                <li className="nav-item  dropdown" ref={profileRef}>
                  <div
                    className="d-flex align-items-center nav-link dropdown-toggle me-0"
                    role="button"
                    tabIndex="0"
                    id="profileDropdown"
                    onClick={() => setProfileDropdown(!profileDropdown)}
                  >
                    <button type="button" className="btn btn-light rounded-pill">
                      <Menu />
                      <User />
                    </button>
                  </div>
                  <div
                    className={`dropdown-menu dropdown-menu-right navbar-dropdown ${
                      profileDropdown ? 'show' : ''
                    }`}
                  >
                    <Link to="/register-as-tenant">
                      <div
                        role="button"
                        tabIndex="0"
                        className="dropdown-item"
                        onClick={() => {
                          setProfileDropdown(false);
                        }}
                      >
                        <span>{t('label.register_as_a_tenant')}</span>
                      </div>
                    </Link>
                    {!isPartner && (
                      <Link to="/register-as-landlord">
                        <div
                          role="button"
                          tabIndex="0"
                          className="dropdown-item"
                          onClick={() => {
                            setProfileDropdown(false);
                          }}
                        >
                          <span>{t('label.become_a_landlord')}</span>
                        </div>
                      </Link>
                    )}

                    <div className="dropdown-divider" />

                    <div
                      className="dropdown-item"
                      role="button"
                      tabIndex={0}
                      onClick={() => window.open('https://forvaltning.uniteliving.no', '_self')}
                    >
                      <span>{t('property_management.property_management')}</span>
                    </div>

                    <Link to="/privacy-policy" onClick={updateMenu}>
                      <div className="dropdown-item">
                        <span className="font-weight-400 font-12">
                          {t('common.privacy_policy')}
                        </span>
                      </div>
                    </Link>

                    <Link to="/terms-conditions" onClick={updateMenu}>
                      <div className="dropdown-item">
                        <span className="font-weight-400 font-12">
                          {t('common.terms_and_conditions')}
                        </span>
                      </div>
                    </Link>

                    <div
                      role="button"
                      tabIndex="0"
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#sign_in_modal"
                      onClick={() => {
                        setProfileDropdown(false);
                      }}
                    >
                      <span>{t('label.login')}</span>
                    </div>
                  </div>
                </li>
              ) : (
                <li className="nav-item  dropdown" ref={profileRef}>
                  <div
                    className="d-flex align-items-center nav-link dropdown-toggle me-0"
                    role="button"
                    tabIndex="0"
                    id="profileDropdown"
                    onClick={() => setProfileDropdown(!profileDropdown)}
                  >
                    <button
                      type="button"
                      className="d-flex align-items-center btn btn-lg btn-light rounded-pill"
                    >
                      <User />
                      <h6 className="montserrat-500 font-14 text-faded-black">
                        {(userInfo?.name && truncateString(userInfo?.name, 15)) ||
                          getUserInfo()?.name}
                      </h6>
                    </button>
                  </div>

                  <div
                    className={`dropdown-menu dropdown-menu-right navbar-dropdown ${
                      profileDropdown ? 'show' : ''
                    }`}
                  >
                    {canShowLeaseLink() && (
                      <Link to="/lease">
                        <div role="button" tabIndex="0" className="dropdown-item">
                          <span className="svg-icon-16 me-2">
                            <LeaseIcon />
                          </span>

                          <span>{t('label.lease')}</span>
                        </div>
                      </Link>
                    )}
                    <Link to="/inbox">
                      {/* svg-icon-16 me-2 */}
                      <div
                        role="button"
                        tabIndex="0"
                        className="dropdown-item"
                        onClick={() => {
                          setProfileDropdown(false);
                        }}
                      >
                        <span className="svg-icon-16 me-2">
                          <Inbox />
                        </span>

                        <span>{t('label.inbox')}</span>
                      </div>
                    </Link>
                    {canShowMylisintgLink() && (
                      <Link to="/my-listing">
                        <div
                          role="button"
                          tabIndex="0"
                          className="dropdown-item"
                          onClick={() => {
                            setProfileDropdown(false);
                          }}
                        >
                          <span className="svg-icon-16 me-2">
                            <Star />
                          </span>

                          <span>{t('label.my_listing')}</span>
                        </div>
                      </Link>
                    )}
                    <Link to="/profile">
                      <div
                        role="button"
                        tabIndex="0"
                        className="dropdown-item"
                        onClick={() => {
                          setProfileDropdown(false);
                        }}
                      >
                        <span className="svg-icon-16 me-2">
                          <User />
                        </span>
                        <span>{t('label.profile')}</span>
                      </div>
                    </Link>
                    <Link to="/login">
                      <div
                        role="button"
                        tabIndex="0"
                        className="dropdown-item"
                        onClick={() => {
                          setProfileDropdown(false);
                          logOut();
                        }}
                      >
                        <span className="svg-icon-16 me-2">
                          <LogOut />
                        </span>
                        <span>{t('label.logout')}</span>
                      </div>
                    </Link>
                    <hr />
                    {!partner?.isSubDomainExists && (
                      <>
                        <a href="https://forvaltning.uniteliving.no">
                          <div
                            role="button"
                            tabIndex="0"
                            className="dropdown-item"
                            onClick={() => {
                              setProfileDropdown(false);
                            }}
                          >
                            <span>{t('label.property_managment_system')}</span>
                          </div>
                        </a>

                        <NavLink to="/privacy-policy" onClick={updateMenu}>
                          <div className="dropdown-item">
                            <span className="font-weight-400 font-12">
                              {t('common.privacy_policy')}
                            </span>
                          </div>
                        </NavLink>

                        <NavLink to="/terms-conditions" onClick={updateMenu}>
                          <div className="dropdown-item">
                            <span className="font-weight-400 font-12">
                              {t('common.terms_and_conditions')}
                            </span>
                          </div>
                        </NavLink>
                      </>
                    )}
                  </div>
                </li>
              )}
            </ul>
          </div>

          <div className="py-3 d-lg-none col-6 col-sm-8">
            <SearchBar />
          </div>

          {location.pathname === '/find-home' ? (
            <div className="btn-filter col-1 col-md-1 col-lg-2 d-flex justify-content-end topbar-search mx-lg-5 d-lg-none ms-3 ms-sm-0">
              <div
                className={`cursor-pointer ${isButtonDisable() ? 'filter-active' : ''}`}
                data-bs-toggle="modal"
                data-bs-target="#more-filter-model"
              >
                <button type="button" className="rounded-pill btn-light btn btn-sm">
                  <Filter />
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* {location.pathname === '/find-home' && !isLogedIn ? (
            <div className="col-2 col-md-1 d-lg-none">
              <button
                className="svg-icon-30 border-0 bg-white"
                type="button"
                onClick={toggleSearch}
                aria-expanded={showSearch}
                aria-label="Toggle search"
              >
                <Search />
              </button>
            </div>
          ) : (
            <></>
          )} */}

          <div className="col-1 col-sm-1 d-lg-none me-md-0 col-md-1 d-lg-none me-3 me-md-0 d-flex justify-content-center">
            <div
              className="d-flex align-items-center rounded-pill"
              onClick={updateMenu}
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasTop"
              aria-controls="offcanvasTop"
            >
              <button type="button" className="rounded-pill btn-light btn btn-sm">
                <User />
              </button>
            </div>

            <div className={menuClass} />
          </div>

          {showSearch && (
            <div className="py-3 col-6 col-lg-12 d-none d-sm-none d-lg-block">
              <SearchBar />
            </div>
          )}
        </div>
      </nav>

      <SignInModal id="sign_in_modal" />
      <NavModal onHandleDismiss={updateMenu} id="offcanvasTop" />
    </>
  );
};

export default Navbar;
