/* eslint-disable prettier/prettier */
import React, { useState, useRef, useEffect } from 'react';
import { ChevronUp, Globe, LogOut } from 'react-feather';
import { Link, NavLink } from 'react-router-dom';
import { size } from 'lodash';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toastCall, translateLanguage, isAuthenticated, getUserId } from '@/core/utils';
import { UPDATE_USER_PROFILE } from './graphql/updateUserProfile.gql';
import GET_USER_PROFILE from './graphql/getUserProfile.gql';
import { logOut } from '../../core/utils';
// language Data
const languageData = {
  en: 'english',
  no: 'norwegian'
};

function NavModal({ id, updateMenu }) {
  const { t, i18n } = useTranslation();

  const [profileDropdown, setProfileDropdown] = useState(false);
  const [registerDropdown, setRegisterDropdown] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const [language, setLanguage] = useState(Cookies.get('language') || 'en');
  const [originalLanguage, setOriginalLanguage] = useState('en');
  const [disable, setDisable] = useState(false);
  const [isLogedIn, setIsLogedIn] = useState(null);

  const profileRef = useRef();
  const registerRef = useRef();
  const languageRef = useRef();
  const userId = getUserId();

  // Mutation: Update Language
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);
  const changeLanguage = async (lan) => {
    i18n.changeLanguage(lan);
    setLanguage(lan);
    try {
      const result = await updateUserProfile({
        variables: {
          inputData: {
            _id: userId,
            data: {
              language: lan
            }
          }
        }
      });
      if (result) {
        setDisable(false);
      }
    } catch (e) {
      i18n.changeLanguage(originalLanguage);
      setLanguage(originalLanguage);
      setDisable(false);
      toastCall('danger', e.message, 'top-right');
    }
  };

  const handleChange = async (item) => {
    setDisable(true);
    if (isAuthenticated()) {
      changeLanguage(item);
    } else {
      i18n.changeLanguage(item);
      Cookies.set('language', item);
      setLanguage(item);
      setDisable(false);
    }
  };

  const handleClickOutside = (e) => {
    if (!languageRef.current.contains(e.target)) {
      setLanguageDropdown(false);
    }
  };

  const [myProfile] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'network-only',
    variables: {
      queryData: { userId }
    },
    onCompleted: (data) => {
      const userLanguage = data?.myProfile?.language || 'en';
      if (size(userLanguage)) {
        i18n.changeLanguage(userLanguage);
        setLanguage(userLanguage);
        setOriginalLanguage(userLanguage);
      }
      setDisable(false);
    }
  });

  useEffect(() => {
    setDisable(true);
    if (isAuthenticated()) {
      myProfile();
    } else if (size(language)) {
      i18n.changeLanguage(language);
      setDisable(false);
    }
  }, [isAuthenticated()]);

  useEffect(() => {
    if (profileDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileDropdown]);

  useEffect(() => {
    if (registerDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [registerDropdown]);

  useEffect(() => {
    if (languageDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [languageDropdown]);

  useEffect(() => {
    if (size(getUserId())) {
      setIsLogedIn(Cookies.get('accessToken'));
    } else {
      setIsLogedIn(null);
    }
  }, [getUserId()]);

  return (
    <div className="offBar">
      <div
        className="offcanvas offcanvas-top z-index-1090"
        // data-bs-dismiss="offcanvas"
        tabIndex="-1"
        id={id}
        aria-labelledby="offcanvasTopLabel"
        style={{ height: '100vh' }}
      >
        <div className="offcanvas-body mt-5 pt-5 ms-3 me-3 text-faded-black">
          <button
            className="position-absolute top-0 end-0 btn btn-tertiary mx-2 my-2"
            type="button"
            onClick={updateMenu}
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasTop"
            aria-controls="offcanvasTop"
          >
            {t('Close')}
          </button>
          <div style={{ wordSpacing: '0.5px', letterSpacing: '0.5px' }}>
            {isLogedIn === null ? (
              <div
                className="margin-b-25"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasTop"
                aria-controls="offcanvasTop"
              >
                <Link to="/register-as-tenant/create-account" onClick={updateMenu}>
                  <h3 className="font-weight-500 font-20 text-faded-black d-inline">
                    {t('label.register_as_a_tenant')}
                  </h3>
                </Link>
              </div>
            ) : (
              <></>
            )}

            {isLogedIn === null ? (
              <div
                className="margin-b-25"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasTop"
                aria-controls="offcanvasTop"
              >
                <Link to="/register-as-landlord" onClick={updateMenu}>
                  <h3 className="font-weight-500 font-20 text-faded-black d-inline">
                    {t('label.become_a_landlord')}
                  </h3>
                </Link>
              </div>
            ) : (
              <></>
            )}
          </div>
          {isLogedIn === null ? (
            <div
              className="margin-b-20"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasTop"
              aria-controls="offcanvasTop"
            >
              <NavLink to="/login" onClick={updateMenu}>
                <h3 className="font-weight-400 font-20 text-faded-black d-inline">
                  {t('common.log_in')}
                </h3>
              </NavLink>
            </div>
          ) : (
            <></>
          )}
          {isLogedIn !== null ? (
            <div
              className="margin-b-20"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasTop"
              aria-controls="offcanvasTop"
            >
              <NavLink to="/inbox" onClick={updateMenu}>
                <div role="button" tabIndex="0">
                  <h3 className="font-weight-400 font-20 text-faded-black d-inline">
                    {t('label.inbox')}
                  </h3>
                </div>
              </NavLink>
            </div>
          ) : (
            <></>
          )}

          {isLogedIn !== null ? (
            <div
              className="margin-b-20"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasTop"
              aria-controls="offcanvasTop"
            >
              <NavLink to="/my-listing" onClick={updateMenu}>
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    setProfileDropdown(false);
                  }}
                >
                  <h3 className="font-weight-400 font-20 text-faded-black d-inline">
                    {t('label.my_listing')}
                  </h3>
                </div>
              </NavLink>
            </div>
          ) : (
            <></>
          )}

          {isLogedIn !== null ? (
            <div
              className="margin-b-20"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasTop"
              aria-controls="offcanvasTop"
            >
              <NavLink to="/profile" onClick={updateMenu}>
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    setProfileDropdown(false);
                  }}
                >
                  <h3 className="font-weight-400 font-20 text-faded-black d-inline">
                    {t('label.profile')}
                  </h3>
                </div>
              </NavLink>
            </div>
          ) : (
            <></>
          )}

          {isLogedIn !== null ? (
            <div
              className="margin-b-20"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasTop"
              aria-controls="offcanvasTop"
            >
              <NavLink to="/login" onClick={updateMenu}>
                <div
                  className="d-flex align-items-center"
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    setProfileDropdown(false);
                    logOut();
                  }}
                >
                  <span className="svg-icon-16 me-2">
                    <LogOut className="text-danger" />
                  </span>
                  <h3 className="font-weight-400 font-20 text-faded-black d-inline">
                    {t('label.logout')}
                  </h3>
                </div>
              </NavLink>
            </div>
          ) : (
            <></>
          )}
          <></>
          <div className="dropdown-divider-2 margin-b-20 " style={{ height: '10px' }} />
          <div
            className="margin-b-25"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasTop"
            aria-controls="offcanvasTop"
          >
            <NavLink to="/find-home" onClick={updateMenu}>
              <h3 className="font-weight-400 font-20 text-faded-black d-inline">
                {t('homepage.find_home')}
              </h3>
            </NavLink>
          </div>
          {isLogedIn !== null ? (
            <div
              className="margin-b-25"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasTop"
              aria-controls="offcanvasTop"
            >
              <NavLink to="/list-your-place/listing-details" onClick={updateMenu}>
                <h3 className="font-weight-400 font-20 text-faded-black d-inline">
                  {t('list_your_place.list_your_place')}
                </h3>
              </NavLink>
            </div>
          ) : (
            <></>
          )}
          <div
            className="margin-b-25"
            role="button"
            tabIndex={0}
            onClick={() => window.open('https://forvaltning.uniteliving.no', '_self')}
          >
            <h3 className="font-weight-400 font-20 d-inline">
              {t('property_management.property_management')}
            </h3>
          </div>

          <div
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasTop"
            aria-controls="offcanvasTop"
          >
            <NavLink to="/privacy-policy" onClick={updateMenu}>
              <div className="text-cream-white mb-2">
                <h3 className="font-weight-400 font-14 text-faded-black d-inline">
                  {t('common.privacy_policy')}
                </h3>
              </div>
            </NavLink>
          </div>

          <div
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasTop"
            aria-controls="offcanvasTop"
          >
            <NavLink to="/terms-conditions" onClick={updateMenu}>
              <div className="text-cream-white mb-2">
                <h3 className="font-weight-400 font-14 text-faded-black d-inline">
                  {t('common.terms_and_conditions')}
                </h3>
              </div>
            </NavLink>
          </div>

          <div className="margin-tb-30" />
          <div className="nav-item dropdown d-inline" ref={languageRef}>
            <div
              role="button"
              tabIndex="0"
              onClick={() => setLanguageDropdown(!languageDropdown)}
              className="d-flex justify-content-between align-items-center rounded-pill bg-gray-light  nav-link  dropdown-toggle cursor-pointer navClass"
              id="languageDropdown"
              style={{ maxWidth: '500px' }}
            >
              <div className="d-flex justify-content-between w-100 align-items-center px-2">
                <h5 className="font-weight-400 py-2 text-success">{t('label.english')}</h5>
                <div className="text-faded-black">
                  <span className="svg-icon-20 margin-r-5">
                    <Globe />
                  </span>
                  <span className="svg-icon-18">
                    <ChevronUp />
                  </span>
                </div>
              </div>
            </div>

            <div
              className={`dropdown-menu dropdown-menu-bottom navbar-dropdown language-dropdown ${
                languageDropdown ? 'show' : ''
              }`}
              style={{ position: 'absolute', top: '-120px', left: '20px', width: '230px' }}
            >
              {['en', 'no'].map((languageParams) => (
                <span
                  data-backdrop="static"
                  data-keyboard="false"
                  role="button"
                  tabIndex="0"
                  key={languageParams}
                  className={`dropdown-item ${disable ? 'disabled' : ''}`}
                  onClick={() => {
                    setLanguageDropdown(false);
                    handleChange(languageParams);
                  }}
                >
                  {translateLanguage(`label.${languageData[languageParams]}`)}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavModal;
